import React, { ReactNode } from 'react';
import { Provider } from 'react-redux';

import { store } from '_/store';

import { DeliveriesProvider } from './DeliveriesProvider';
import { HostProvider } from './HostsProvider';
import { LanguageProvider } from './LanguageContext';
import { MeetingsProvider } from './MeetingsProvider';
import { NotificationsProvider } from './NotificationsProvider';
import { PushNotificationProvider } from './PushNotificationProvider';
import { UserProvider } from './UserProvider';
import { VisitsProvider } from './VisitsProvider';
import { SpaceManagementProvider } from './spaceManagementProvider';

interface AppProviderProps {
  children: ReactNode;
}

export default function AppProvider({ children }: AppProviderProps) {
  return (
    <Provider store={store}>
      <LanguageProvider>
        <UserProvider>
          <HostProvider>
            <MeetingsProvider>
              <VisitsProvider>
                <DeliveriesProvider>
                  <SpaceManagementProvider>
                    <NotificationsProvider>
                      <PushNotificationProvider>{children}</PushNotificationProvider>
                    </NotificationsProvider>
                  </SpaceManagementProvider>
                </DeliveriesProvider>
              </VisitsProvider>
            </MeetingsProvider>
          </HostProvider>
        </UserProvider>
      </LanguageProvider>
    </Provider>
  );
}
