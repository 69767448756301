import AsyncStorage from '@react-native-async-storage/async-storage';
import { HostsModel, HostZonesModel } from '@w3lcome/types';
import AllowedHostsDB from '_/services/sqlite/AllowedHostsDB';
import UsersZonesDB from '_/services/sqlite/UsersZonesDB';
import { Platform } from 'react-native';
import { all, put, takeLatest } from 'redux-saga/effects';
import * as Effects from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import {
  getHostOfTheActualCompanyFailure,
  getHostOfTheActualCompanySuccess,
  getHostOfTheActualCompanyRequest,
} from './actions';
import hostsApi from '.././../../services/api/hosts';

const call: any = Effects.call;

export function* getHostOfTheActualCompany({
  payload,
}: ActionType<typeof getHostOfTheActualCompanyRequest>): Generator<any, void, any> {
  const { companyId, userId } = payload;

  try {
    const [host]: HostsModel[] = yield call(hostsApi.getActualHost, {
      companyId,
      userId,
      includeZone: true,
    });

    const hostZones: HostZonesModel[] = yield call(hostsApi.getActualHostZones, {
      hostId: host?.id,
    });

    const existingUsersZonesDB = yield AsyncStorage.getItem('@w3lcome/UsersZonesDB');

    const hostWithZones = {
      ...host,
      hostZones: hostZones ?? [],
    };

    if (hostWithZones && existingUsersZonesDB !== 'true' && Platform.OS !== 'web') {
      UsersZonesDB.init();
      AllowedHostsDB.init();
      yield AsyncStorage.setItem('@w3lcome/UsersZonesDB', 'true');
    }

    yield put(getHostOfTheActualCompanySuccess(hostWithZones));
  } catch (error) {
    console.tron.log('@host/getHostOfTheActualCompany', error);
    yield put(getHostOfTheActualCompanyFailure());
  }
}

export default all([
  takeLatest('@host/GET_HOST_OF_THE_ACTUAL_COMPANY_REQUEST', getHostOfTheActualCompany),
]);
