import { SpaceManagementModel } from '_/models/spaceManagement';
import { SpaceManagementApi } from '_/services/api';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface SpaceManagementData {
  spaceManagement: SpaceManagementModel | undefined;
}

const SpaceManagementContext = createContext<SpaceManagementData>({} as SpaceManagementData);

type SpaceManagementType = {
  children: React.ReactNode;
};

export const SpaceManagementProvider: React.FC<SpaceManagementType> = ({ children }) => {
  const [spaceManagement, setSpaceManagement] = useState<SpaceManagementModel>();
  const currentCompanyId = useSelector((state: any) => state.user.currentCompanyId);

  const getSpaceManagement = useCallback(async () => {
    const { data } = await SpaceManagementApi.getSpaceManagement(currentCompanyId);
    setSpaceManagement(data?.[0]);
  }, [currentCompanyId]);

  useEffect(() => {
    getSpaceManagement();
  }, [getSpaceManagement]);

  return (
    <SpaceManagementContext.Provider value={{ spaceManagement }}>
      {children}
    </SpaceManagementContext.Provider>
  );
};

export function useSpaceManagement() {
  const context = useContext(SpaceManagementContext);

  if (!context) {
    throw new Error('useSpaceManagement must be used within an SpaceManagementProvider');
  }
  return context;
}
